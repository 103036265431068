import {Link} from "koye"
import PropTypes from "prop-types"
import React, { useState, useEffect }  from "react"
// import {parallaxRef} from "./layout";
// import KoyeIcon from "../images/svg/icon.svg";
import Headroom from "./headroom-component";
import PlayStore from "../images/play_store_icon.png";
import PlayStoreWhite from "../images/play_store_icon_white.png";
import LogoHeader from "../images/logo_header.png";
import Logo from "../images/logo.png";


const Header = () => {
  let windowMatchMedia;
  if (typeof window !== `undefined`) {
    windowMatchMedia = window.matchMedia;
  }
  const [logo_header, setLogo] = useState(LogoHeader);
  const [playStoreIcon, setPlayStoreIcon] = useState(PlayStore);

  useEffect(() => {
    setLogo(windowMatchMedia && windowMatchMedia('(prefers-color-scheme: dark)').matches ? Logo : LogoHeader);
    setPlayStoreIcon(windowMatchMedia && windowMatchMedia('(prefers-color-scheme: dark)').matches ? PlayStoreWhite : PlayStore);
    windowMatchMedia('(prefers-color-scheme: dark)').addListener(({ matches }) => {
      setLogo(matches ? Logo : LogoHeader);
      setPlayStoreIcon(matches ? PlayStoreWhite : PlayStore);
    });
    return () => {
      windowMatchMedia('(prefers-color-scheme: dark)').removeListener(({ matches }) => {
        setLogo(matches ? Logo : LogoHeader);
        setPlayStoreIcon(matches ? PlayStoreWhite : PlayStore);
      });
    }
  }, []);

  return (
    <Headroom upTolerance={0} downTolerance={1000} pinStart={200}>
    <header
        style={{
            background: 'white',
            zIndex: 1,
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 5px 20px'
            /*marginBottom: `1.45rem`*/
        }}
        className={"blur"}
    >
        <div
            style={{
                margin: `0 auto`,
                maxWidth: 960,
                padding: `15px`,
                flex: 1,
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <Link
                to="/"
                // onClick={parallaxRef ? () => parallaxRef.scrollTo(0) : () => {}}
                style={{
                    color: '#333333',
                    textDecoration: `none`,
                    flex: 1,
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <img src={logo_header} style={{marginRight: '0.725rem', marginBottom: 0}} width={40} height={40} className={"hide-logo"} alt=""/>
                <span className={"header-logo"} style={{color: '333333', fontSize: 45, fontFamily: 'RooneySans, sans-serif'}}>Koyeto</span>
            </Link>
            <nav>
                <ul style={{flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                    <li>
                      <a style={{
                          color: `white`,
                          textDecoration: `none`,
                        }}
                        href="https://play.google.com/store/apps/details?id=app.koyeto.android"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={playStoreIcon} style={{display: 'flex'}} className={"icon-size button button-black button-hover button-active"} alt=""/>
                      </a>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
    </Headroom>
)};

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header
