import {createGlobalStyle} from "styled-components";

import '../fonts/fonts.css'

const GlobalStyle = createGlobalStyle `

  h1 {
  margin-left: 0;
  margin-right: 0;
  margin-top: -1.5rem;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: 'RooneySans','-apple-system', sans-serif;
  font-weight: initial;
  text-rendering: optimizeLegibility;
  font-size: 520%;
  line-height: 1.1;
}`;

export default GlobalStyle;